export var navlinks = {
    root: "/",
    login: function login() {
        return navlinks.root.concat("login/");
    },
    page404: function page404() {
        return navlinks.root.concat("404/");
    },
    catalog: {
        root: function root() {
            return navlinks.root.concat("catalog/");
        },
        landing: function landing() {
            return navlinks.catalog.root().concat("landing/");
        },
        byId: function byId(_ref) {
            var id = _ref.id;
            return navlinks.catalog.root().concat(id, "/");
        },
        cart: {
            root: function root() {
                return navlinks.catalog.root().concat("cart/");
            }
        },
        checkout: {
            root: function root() {
                return navlinks.catalog.root().concat("checkout/");
            }
        },
        items: {
            root: function root() {
                return navlinks.catalog.root().concat("items/");
            },
            byId: function byId(_ref2) {
                var id = _ref2.id;
                return navlinks.catalog.items.root().concat(id);
            }
        }
    },
    admin: {
        root: function root() {
            return navlinks.root.concat("admin/");
        },
        about: function about() {
            return navlinks.admin.root().concat("/about");
        }
    },
    legacy_app: {
        root: function root() {
            return "https://lk-dev.smfox.ru/";
        },
        costEstimate: function costEstimate() {
            return navlinks.legacy_app.root().concat("client/new-application/cost-estimate");
        },
        profile: function profile() {
            return navlinks.legacy_app.root().concat("client/profile/");
        },
        companySettings: function companySettings() {
            return navlinks.legacy_app.root().concat("client/settings/company/");
        },
        guide: function guide() {
            return navlinks.legacy_app.root().concat("client/guide/");
        },
        garage: function garage() {
            return navlinks.legacy_app.root().concat("client/garage/");
        },
        support: function support() {
            return navlinks.legacy_app.root().concat("client/support/");
        }
    }
};
